.features {
    display: flex;
    margin: 20px;
    align-items: center;
    justify-content: center;
}

.search-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-right: 10px;
}

.search-input::placeholder {
    color: #999;
}

.search-button {
    padding: 8px 12px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.search-button:hover {
    background-color: #0056b3;
}

.search-button:focus {
    outline: none;
}

.handleSearchBtn {
    padding: 10px 20px;
    background-color: #ff6c98;
    border: none;
    border-radius: 12px;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: #ffffff;
    text-decoration: none;
    display: flex;
    cursor: pointer;
}

.handleSearchBtn:hover {
    background-color: #ff467d;
}

.filter-container {
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.filter-select {
    display: inline-block;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
}

.pagenav-section {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.left-pagenav-text {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 27px;
    color: #1e1e1e;
    padding: 10px;
}

.row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 90%;
    margin: 10px auto;
    gap: 1rem;
}

.pagination {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px 5px;
    gap: 10px;
}

.left-pagenav {
    margin: auto;
}

.pagination a {
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 29px;
}

.pagination a.active {
    background: #ff5f8f;
    border-radius: 5px;
}

.pagination a:hover:not(.active) {
    background-color: #ddd;
    border-radius: 5px;
}

.right-pagenav {
    margin: auto;
    cursor: pointer;
}

.right-pagenav-text {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 27px;
    color: #1e1e1e;
    padding: 10px;;
}

@media (max-width: 768px) {
    .row {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .row {
        grid-template-columns: repeat(1, 1fr);
    }
}