@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

.modalBackground {
    width: 100vw;
    height: 2000px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40%;
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.3);
    font-family: Arial, sans-serif;
}

.modalContainer {
    width: 500px;
    height: 610px;
    border-radius: 12px;
    background-color: white;
    box-shadow: #ff6c98 0px 5px 25px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    display: inline-block;
    text-align: center;
    margin-top: 80px;
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
}

.titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.top-section {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff6f9;
    padding-top: 10px;
    border-radius: 30px;
    box-shadow: #a70b39 0px 3px 5px;
}

.hr-custom {
    width: 50%;
    margin: 20px auto;
    border: none;
    background-color: #ddd;
    height: 2px;
}


.input-container {
    position: relative;
    margin: 10px auto;
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.input-label {
    display: inline-block;
    padding: 12px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ff6c98;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.uploadImg {
    display: inline-block;
    margin-bottom: 12px;
    padding: 12px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ff6c98;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.bottom-section {
    font-size: 1.25rem;
    font-family: "Nunito", sans-serif;
    position: relative;
    padding: 2px;
}

.bottom-section p {
    font-family: Arial, sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
    text-align: justify;
    padding: 8px;
    letter-spacing: 1px;
    text-align: center;
}

.editBtn {
    position: absolute;
    bottom: 0;
    right: 0;
    align-self: flex-end;
    padding: 5px;
    background-color: #ff6c98;
    font-size: 25px;
    color: #fff;
    border: none;
    border-radius: 30%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.input-icon {
    margin-right: 5px;
}

#upload-photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
}


.imageProf {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid #ff6c98;
    object-fit: cover;
}

.imputImg {
    border: 2px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    background-color: #f8f8f8;
    color: #333;
    font-size: 16px;
    width: 120px;
}

.modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-mode input {
    font-size: 1.25rem;
    font-family: "Nunito", sans-serif;
    position: relative;
    display: block;
    margin: 10px 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.edit-mode button {
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #4CAF50;
    color: white;
    border-radius: 3px;
    cursor: pointer;
}

.edit-mode button:hover {
    background-color: #45a049;
}

.view-mode {
    display: flex;
    flex-direction: column;
}

.view-mode p {
    margin: 5px 0;
}