@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding-left: 20px;
    padding-right: 20px;
}

.navmainlink {
    text-decoration: none;
}

.nav-logo {
    display: flex;
    flex-direction: row;
}

.nav-logo-text {
    font-size: 1.5rem;
    font-family: "Nunito", sans-serif;
    font-weight: bold;
    margin-left: 10px;
    color: #ff6c98;
}

.nav-links {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    text-decoration: none;
}

.item {
    text-decoration: none;
    text-align: center;
    font-size: 1.25rem;
    font-family: "Nunito", sans-serif;
    padding: 10px 15px;
    margin: auto;
    cursor: pointer;
    color: #000000;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.item:hover {
    font-weight: bold;
    color: #ff6c98;
}

.mobile-menu-icon {
    display: none;
}

.nav-links-mobile {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    list-style: none;
    background-color: #1746a2;
    z-index: 3;
}

.logout-btn {
    margin: auto 10px;
    padding: 10px 20px;
    background-color: #ff6c98;
    border: none;
    border-radius: 12px;
    font-family: "Nunito", sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s;
    /* Adding transition for smooth color change */
}

.logout-btn:hover {
    background-color: #f4326c;
}


@media (max-width: 768px) {
    .nav-links {
        display: none;
    }

    .mobile-menu-icon {
        display: flex;
        font-size: 2.35rem;
        color: #fff;
        background-color: transparent;
        border: none;
        outline: none;
        justify-content: flex-end;
        cursor: pointer;
    }

    .nav-links-mobile {
        top: 80px;
        right: 0;
        width: 30%;
        height: 30%;
    }

    .item {
        padding: 0;
        margin-bottom: 5px;
        margin-top: 5px;
    }
}