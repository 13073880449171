.Addblog-btn {
    padding: 10px 20px;
    background-color: #ff6c98;
    border: none;
    border-radius: 12px;
    font-family: "Nunito", sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    color: #ffffff;
    text-decoration: none;
    display: flex;
    margin: auto;
    width: fit-content;
}
