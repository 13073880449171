/* @import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

.profile-page {
    font-size: 1.25rem;
    font-family: "Nunito", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;

    min-height: 80vh;
    border: 5px solid #ff6c98;
    border-radius: 30px;
    margin: 20px 80px;
    padding: 20px;
}

.left-section,
.right-section {
    width: 50%;
    padding: 20px;
    margin-right: 20px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.left-section img {
    width: 350px;
    height: 350px;
    margin-left: 20%;
    border-radius: 50%;
    border: 3px solid #ff6c98;
    object-fit: cover;
}

.right-section p {
    margin-bottom: 10px;
}

.right-section input {
    margin-bottom: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.right-section button {
    padding: 10px 20px;
    background-color: #ff6c98;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.right-section button:hover {
    background-color: #f4326c;
} */

@media only screen and (max-width: 768px) {
    .left-section img {
        margin-left: 0;
    }

    .profile-page {
        padding: 10px;
        /* Added padding for mobile */
    }

    .left-section,
    .right-section {
        padding: 10px;
        width: 100%;
    }
}