
@import url('https://fonts.googleapis.com/css2?family=Belleza&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andika:ital,wght@0,400;0,700;1,400;1,700&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Arima:wght@100..700&display=swap');*/


.blog-body {
    background-color: #FFF1F1;
}

.blog-content {
    position: relative;
    padding-top: 70px;

}
.blog-content-article {
    max-width: 80%;
    /*box-shadow: 0 -1px 56px rgba(255, 95, 143, 0.25),*/
    /*0 1px 59px rgba(255, 95, 143, 0.15);*/
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0.25rem 2rem 5rem;
}

.blog-blocks {
    max-width: 70%;
    margin: auto;
}


.blogcontent-footer {
    margin-top: 30px;
}

.blogcontent-footer-text {
    font-family: "Nunito", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 168%;
    letter-spacing: 0.025em;
    color: #1e1e1e;
    padding-left: 5rem;
}

.blog-card-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    max-width: 90%;
    margin: auto;
}

/* editorjs rendered css start*/
.editor-delimiter {
    width: 200px;
    margin: 6rem auto 2rem;
    border-bottom: 4px dotted hotpink;
}


.editor-embed {
    margin: 1rem 0;
    width: 100%;
    font-size: medium;
    font-family: "Maven Pro", sans-serif;
    color: #505050;
}

.editor-header {
    padding: 2.5rem 0 0.5rem;
    font-weight: bold;
    font-family: "Belleza", sans-serif;
    font-size: 2rem;
    /*font-family: 'Playfair', serif;*/
    /*font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;*/
}

.editor-para {
    font-family: "Andika", sans-serif;
    font-size: 18px;
    line-height: 1.7rem;
    padding-top: 0.5rem;
}

.editor-image {
    margin: 1rem 0;
    width: 80%;
}

.editor-quote {
    font-family: 'Playfair', serif;
    font-size: 1.2rem;
    padding: 0 1.5rem;
    font-style: italic;
    text-align: center;
}

.editor-image {
    display: flex;
    flex-direction: column;
    margin:auto;

    > img {
        align-self: center;

        padding: 5px;
        object-fit: cover;
        height: 100%;
        width: 60%;

    }
    >figcaption{
        align-self: center;

    }
}

.stretched > img{
    width: 100%;
    border: yellow;
    padding: 5px;
    object-fit: fill;
    height: 100%;
}


.withBackground{
    width: 100%;
    border-radius: 12px;
    padding: 5px;
    object-fit: cover;
    height: 100%;
    background-color: rgba(255, 108, 152, 0.31);

}

.withBorder>img{
    border: 1px solid #ddd;
    border-radius: 4px;
}



.editor-list {
    font-family: "Nunito", serif;
    font-size: 1rem;
    padding: 0.2rem 3rem;
    letter-spacing: 0.45px;
    opacity: 0.8;
}

/* editorjs rendered css end */

#blog-title {
    margin: auto;
    font-family: "Arima Madurai", sans-serif;
    font-weight: 700;
    color: #ff6c98;
    font-size: 36px;
    letter-spacing: 1px;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.blogimg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 100%;
}



.blogimg-container img {
    object-fit: cover;
    border-radius: 12px;
    width: 70%;
}

.blogcontent-detail {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.75rem auto auto;
}

.blogcontent-detail-text {
    border-radius: 7px;
    /*background: #f1f1f1;*/
    color: #505050;
    font-size: 16px;
    font-family: "Nunito", serif;
    font-weight: bold;
    letter-spacing: 0.32px;
    padding: 0.8rem 0.3rem;
    margin-right: 1rem;
}

.blocks-container {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}

.blogitem-author {
    display: flex;
}

.blogitem-author img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1rem;
}

.blogitem-author-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .blog-content-article {
        max-width: 98%;
        border-radius: 12px;
        align-items: center;
        justify-content: center;
        margin: auto;
        /*padding: 0.1rem 1rem 2rem;*/
    }

    .blog-blocks {
        max-width: 100%;
        margin: auto;
    }

    .blogimg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        width: 100%;
    }

    .blogimg-container img {
        width: 100%;
    }



    .blocks-container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }


    .blogcontent-footer-text {
        text-align: center;
        padding-left: 0;
    }


    .blog-content h1 {
        font-size: 1.5rem;
        max-width: 100%;
    }
    .blog-content-article {
        max-width: 95%;
        padding: 0.25rem 1.8rem 5rem;
    }
    .editor-para {
        font-family: "Maven Pro", sans-serif;
        font-weight: 300;
        font-size: 18px;
        line-height: 1.7rem;
        padding-top: 0.5rem;
    }
}
