.chip {
    font-size: 0.9rem;
    background: #ff6c98;
    color: #fff;
    margin: 0.2rem 0.5rem 0.2rem 0;
    padding: 0.3rem 0.5rem;
    border-radius: 5px;
    width: fit-content;
    text-transform: capitalize;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
}