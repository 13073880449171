.edit-form-group {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content-container{
    min-width: 100%;
    margin: 20px;
}

label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

input,
textarea {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-family: inherit;
    font-size: 16px;
}

input:focus,
textarea:focus {
    border-color: #66ccff;
}

input::placeholder,
textarea::placeholder {
    color: #999;
}

.slugtext,
.excerpttext {
    display: block;
    margin-top: 5px;
    color: #666;
    font-style: italic;
}

.excerpt-container {
    margin-top: 20px;
}

.btn-primary {
    padding: 0.4rem;
    width: fit-content;
    font-size: 1rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    text-transform: capitalize;
    background-color: #ff6c98;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
}

.toggle-container {
    display: inline-flex;
    position: relative;
    width: 80px;
    height: 40px;
}

.toggle-container input[type="radio"] {
    display: none;
}

.toggle-container label {
    cursor: pointer;
    width: 50%;
    height: 100%;
    background: #ccc;
    border-radius: 20px;
    transition: background 0.3s ease, box-shadow 0.3s ease;
}

.toggle-container label:first-of-type {
    left: 0;
    margin-right: -1px;
}

.toggle-container label:last-of-type {
    right: 0;
    margin-left: -1px;
}

.toggle-container input[type="radio"]:checked + label {
    background: #007bff;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.toggle-container label:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 2;
}

.toggle-container input[type="radio"]:first-of-type:checked + label:before {
    left: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.toggle-container input[type="radio"]:last-of-type:checked + label:before {
    right: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
