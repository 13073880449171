
.tag-container {
    margin: 5px;
    display: flex;
    flex-wrap: wrap;
    background-color: #ff6c98;
    border-radius: 5px;
    font-size: 1rem;
    font-family: "Nunito", sans-serif;
    text-transform: capitalize;
}

.tag-delete {
    font-weight: 600;
    color: #fff;
    padding: 6px;
    cursor: pointer;
}

.tag-text {
    pointer-events: none;
    color: #fff;
    padding: 6px 10px 6px 5px
}