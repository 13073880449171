.category-form {
    margin: 1rem;
}

.category-tag-group {
    display: flex;
    flex-flow: row wrap;
}

.category-group-form {
    margin: 0;
}

.category-group-form label {
    font-size: 1.2rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    margin-right: 0.4rem;
}

.category-group-form input {
    padding: 0.4rem;
}

.category-group-form ::placeholder {
    font-size: 1.2rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
}
