.editblog {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.editblog-text {
    width: fit-content;
    background-color: #0ef;
    color: #000;
    margin: auto;
    margin-top: 1rem;
    padding: 0.4rem 0.7rem;
    border-radius: 5px;
}

.edit-form-group {
    margin: auto;
    margin-top: 1rem;
    width: 900px;
    padding: 1rem;
}

.edit-form-group label {
    font-size: 1.2rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    margin-right: 0.4rem;
    padding: 0.4rem;
    justify-content: center;
    align-items: center;
}

.edit-form-group input {
    padding: 0.4rem;
    width: 90%;
    font-size: 1rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    text-transform: capitalize;
}

.edit-form-group textarea {
    padding: 0.4rem;
    width: 95%;
    height: 6rem;
    resize: none;
    margin-top: 0.6rem;
    font-size: 0.9rem;
}

.slugtext,
.excerpttext {
    font-family: "Nunito", sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 0.5rem;
}

.excerpt-container {
    display: flex;
    flex-direction: column;
}

.featured {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.featured label {
    font-size: 1.6rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    padding: 0.4rem;
    margin: auto;
}

.feature-image-container {
    width: 70%;
    margin-top: 2rem;
}

.image-area {
    margin-top: 1rem;
    margin-bottom: 0.7rem;
    padding: 0.4rem;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #0ef;
}

.image-area input {
    padding: 0.4rem;
    width: fit-content;
    font-size: 1rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    text-transform: capitalize;
}

.blog-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.remove-image-btn {
    padding: 0.4rem;
    width: fit-content;
    font-size: 1rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    text-transform: capitalize;
    background-color: #ff6c98;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: auto;
}

.editblog-btm-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 35rem;
}

.editblog-btm-container h6 {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    margin-right: 1rem;
}

.btn-primary {
    padding: 0.4rem;
    width: fit-content;
    font-size: 1rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    text-transform: capitalize;
    background-color: #ff6c98;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
}

.form-check-inline {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
}

.form-check-inline input {
    font-size: 2rem;
}

.form-check-inline label {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    margin-right: 0.4rem;
    padding: 0.4rem;
    justify-content: center;
    align-items: center;
}

.btm-form-group label {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    margin-right: 0.4rem;
    padding: 0.4rem;
}

.author-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid #ff6c98;
    width: 15%;
}

.avatar-image {
    width: 20%;
    border-radius: 50%;
    object-fit: contain;
    padding: 5px;
}

.author-card-title {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    padding: 10px 0px 10px 0px;
}

.btn-group {
    display: flex;
    flex-direction: row;
}