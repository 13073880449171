.blogItem-wrap {
    display: flex;
    flex-direction: column;
}

.card {
    padding: 0.5rem;
    background: #ffffff;
    border: 2px solid #ff6c98;
    box-shadow: 0px -1px 4px rgba(170, 29, 71, 0.25),
    0px 1px 4px rgba(255, 95, 143, 0.25);
    border-radius: 12px;
    list-style: none;
    transition: all 0.3s ease-in-out;
    height: 100%;
}

.blogItem-cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 0.5rem;
}

.blogItem-wrap h3 {
    margin: 0.4rem 0 0.3rem 0;
    font-size: 1.25rem;
    font-family: "Nunito", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical
}

.blogItem-desc {
    max-height: 4rem;
    padding-right: 0.6rem;
    font-size: 1rem;
    color: #252323b1;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Nunito", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical
}

.blogItem-wrap footer {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    justify-content: space-between;
}

.blogItem-link {
    text-decoration: none;
    color: inherit;
}

.blogItem-author {
    display: flex;
    align-items: center;
}

.blogItem-author img {
    width: 20%;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.5rem;
}

.blogItem-author h6 {
    font-size: 0.8rem;
    color: #454444;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
}

.blogItem-author p {
    font-size: 0.8rem;
    color: #454444;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
}

.published-text {
    width: fit-content;
    color: #454444;
    background-color: #0ef;
    color: #000;
    padding: 5px 10px;
    margin-bottom: 0.7rem;
    border-radius: 5px;
}

.card-category-box {
    display: flex;
    flex-direction: row;
}