.container {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 4rem;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}

.container h1 {
    text-align: center;
}

.container form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container form input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.container form button {
    width: 30%;
    margin-top: 10px;
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.container form button:hover {
    background-color: #45a049;
}

.container form p {
    color: red;
    margin-top: 10px;
}